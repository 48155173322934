<template>
  <div>
    <div class="grid-wrapper__btn broadcast-sidebar-btns align-items-center mb-2">
      <b-button v-if="canAddProgramRelease" class="add-pr-release" size="sm" @click="$emit('showModalAddEditPrRelease', 'add')">
        {{ $t('broadcastSideBar.addProgram') }}
      </b-button>

      <template v-if="canCopyDay">
        <b-button v-b-modal.copy-day-modal size="sm">
          {{ $t('broadcastSideBar.copyDay') }}
        </b-button>
        <ModalCopyDay :channel="channel" @dayCopied="$emit('addProgram', lastUsed === 'day' ? 'day' : 'update')" />
      </template>

      <template v-if="canDeleteDay">
        <b-button v-b-modal.delete-day-modal size="sm">
          {{ $t('broadcastSideBar.deleteDay') }}
        </b-button>
        <ModalDeleteDay :channel="channel" @dayDeleted="$emit('addProgram', lastUsed === 'day' ? 'day' : 'update')" />
      </template>

      <template v-if="canCopyWeek">
        <b-button v-b-modal.copy-period-modal size="sm">
          {{ $t('broadcastSideBar.copyPeriod') }}
        </b-button>
        <ModalCopyPeriod :channel="channel" @periodCopied="$emit('addProgram', lastUsed === 'day' ? 'day' : 'update')" />
      </template>

      <template v-if="canDeleteWeek">
        <ModalDeletePeriod :channel="channel" @periodDeleted="$emit('addProgram', lastUsed === 'day' ? 'day' : 'update')" />
        <b-button v-b-modal.delete-period-modal size="sm">
          {{ $t('broadcastSideBar.deletePeriod') }}
        </b-button>
      </template>

      <template v-if="canAccessClosingCalendar">
        <ModalClosingCalendar :channel="channel" :start-week-day="startWeekDay" @closeEvent="$emit('addProgram', lastUsed === 'day' ? 'day' : 'update')" />
        <b-button v-b-modal.closing-calendar-modal size="sm">
          {{ $t('broadcastSideBar.closingCalendar') }}
        </b-button>
      </template>

      <router-link
        v-if="canViewBroadcastOneDay"
        :to="
          `/broadcast-one-day?channel_id=${channel.id}&date=${startDay}${measurement ? '&measurement_id=' + measurement.id : ''}` +
          `${target ? '&target_id=' + target.id : ''}${commercial ? '&commercial_type_id=' + commercial.id : ''}${block ? '&block_type_id=' + block.id : ''}`
        "
        class="btn btn-outline-danger btn-sm"
      >
        {{ $t('broadcastSideBar.oneDay') }}
      </router-link>

      <template v-if="canExportChannelBroadcast">
        <ModalExportPlaylist :channel="channel" :date="startDay" :commercial_type="commercial" :measurement_company="measurement" :target_audience="target" />
        <b-button v-b-modal.export-playlist size="sm">
          {{ $t('broadcastSideBar.playList') }}
        </b-button>
      </template>

      <router-link
        v-if="canViewMarketingGrid"
        class="btn btn-outline-danger btn-sm"
        :to="{
          name: 'marketingGrid',
          query: {
            target_id: target ? target.id : undefined,
            measurement_id: measurement ? measurement.id : undefined,
            commercial_type_id: commercial ? commercial.id : undefined,
            block_type_id: block ? block.id : undefined,
            channel_id: channel ? channel.id : undefined,
            date: startDay,
          },
        }"
      >
        {{ $t('table.marketing') }}
      </router-link>

      <router-link
        v-if="canReadAutomaticsList"
        :to="{
          name: 'automaticBooking',
          query: {
            channel_id: channel ? channel.id : undefined,
            ...month_from_to,
          },
        }"
        class="btn btn-outline-danger btn-sm"
      >
        {{ $t('broadcastSideBar.automatControl') }}
      </router-link>

      <router-link v-if="canReadCommercialsList" to="/commercials-list" class="btn btn-outline-danger btn-sm">
        {{ $t('broadcastSideBar.commercialControl') }}
      </router-link>

      <b-button v-if="canExportChannelBroadcast" :disabled="exporting" size="sm" @click="$emit('exportReport')">
        {{ exporting ? $t('table.exporting') + '...' : $t('table.export') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BroadcastSideBar',
  components: {
    ModalExportPlaylist: () => import('@/components/Broadcast/ModalExportPlaylist'),
    ModalCopyDay: () => import('@/components/Broadcast/ModalCopyDay.vue'),
    ModalCopyPeriod: () => import('@/components/Broadcast/ModalCopyPeriod.vue'),
    ModalDeleteDay: () => import('@/components/Broadcast/ModalDeleteDay.vue'),
    ModalDeletePeriod: () => import('@/components/Broadcast/ModalDeletePeriod.vue'),
    ModalClosingCalendar: () => import('@/components/Broadcast/ModalClosingCalendar.vue'),
  },
  props: {
    channel: { type: [String, Object], default: undefined },
    lastUsed: { type: String, default: undefined },
    arrowCount: { type: Number, default: undefined },
    commercial: { type: [Object, String], default: undefined },
    block: { type: [Object, String], default: undefined },
    day: { type: String, default: undefined },
    startDay: { type: String, default: undefined },
    target: { type: [Object, String], default: undefined },
    measurement: { type: [Object, String], default: undefined },
    startWeekDay: { type: String, default: undefined },
    exporting: { type: Boolean, default: undefined },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      isLocale: 'isLocale',
    }),
    canCopyWeek() {
      return this.$checkPermissions('broadcasting.channel_copy');
    },
    canCopyDay() {
      return this.$checkPermissions('broadcasting.channel_copy_day');
    },
    canDeleteWeek() {
      return this.$checkPermissions('broadcasting.channel_delete');
    },
    canDeleteDay() {
      return this.$checkPermissions('broadcasting.channel_delete_day');
    },
    canAddProgramRelease() {
      return this.$checkPermissions('program_release.create');
    },
    canExportChannelBroadcast() {
      return this.$checkPermissions('export.channel_broadcasting');
    },
    canReadCommercialsList() {
      return this.$checkPermissions('scope.commercial_list');
    },
    canReadAutomaticsList() {
      return this.$checkPermissions('scope.mediaplan_automatic_list');
    },
    canViewMarketingGrid() {
      return this.$checkPermissions('scope.marketing_grid');
    },
    canViewBroadcastOneDay() {
      return this.$checkPermissions('broadcasting.channel_one_day');
    },
    canAccessClosingCalendar() {
      return this.$checkPermissions('broadcasting.channel_broadcasting_settings');
    },
    month_from_to() {
      //set month start & end for automatic booking props in link
      if (!this.startDay) return;
      const monthFrom = new Date(Date.UTC(+this.startDay.slice(0, 4), +this.startDay.slice(5, 7) - 1, 1));
      const monthTo = new Date(Date.UTC(+this.startDay.slice(0, 4), +this.startDay.slice(5, 7), 0));
      return {
        date_from: monthFrom.toISOString().slice(0, 10),
        date_to: monthTo.toISOString().slice(0, 10),
      };
    },
  },
  destroyed() {},
  methods: {},
};
</script>
